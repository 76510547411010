.wave-container {
    padding-top: 10vh;
    position: relative;
    background: #0F4A74;
    color: #FFF;
    text-align: center;
    overflow: hidden;
}

.wave-container > img {
    display: block;
    margin-bottom: -1px
}

.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 32px;
    padding-right: 32px;
    flex-wrap: wrap;

}

.hero img {
    margin-top: 32px;
    width: 240px;
}

.hero-text {
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
}

.hero-title {
    font-size: 30px;
}

.hero-subtitle {
    padding-top: 3px;
    font-size: 20px;
    color: #CCC;
}

.hero-buttons {
    display: flex;
    justify-content: center;
    margin-top: 12px;
}

.hero-buttons a {
    text-decoration: none;
    color: #EEE;
    margin-left: 30px;
    border: solid 1px;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.hero-buttons a:hover {
    background-color: rgba(255, 255, 255, .7);
    color: #000
}

.active {
    border: 0;
    background-color: #033155;
}