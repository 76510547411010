.index-content {
    display: flex;
    flex-direction: column;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    padding-left: 350px;
    padding-right: 350px;
    color: #212529;
    justify-content: center;
    font-size: 20px;
}

.index-lighter {
    color: #666262;
}

.index-content p {
    margin: 0;
    padding-left: 0;
    padding-top: 10px;
}

.index-content img {
    padding-top: 5px;
    width: 180px;
    align-self: flex-end;
    justify-self: center;
    overflow: hidden;
}

@media only screen and (max-width: 1300px) {
    .index-content {
        padding-left: 32px;
        padding-right: 32px;
        font-size: 16px;
    }
  }

