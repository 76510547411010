.not-found {

}

.not-found-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
}

.not-found-content h1 {
    padding-top: 13px;
    color: #044D87;
}
