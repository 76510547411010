.contact-content {
    padding-top: 3vh;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 120px;
}

.contact-content img {
    padding: 45px;
    width: 250px;
}

.contact-content textarea {
    margin-top:15px;
    width: 92%;
    border: solid 1px #CCCCCC; 
    border-radius: 15px;
    resize: none;
    font-size: 17px;
    padding: 20px;
    align-self: center;
}

.contact-content input[type=submit] {
    align-self: center;
    width: 120px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 17px;
    padding-right: 17px;
    border-style: none;
    border-radius: 7px;
    cursor: pointer;
    background-color: #044D87;
    color: #FFFF;
    font-weight: 600;
}

.contact-fields {
    flex: 1;
    flex-wrap: wrap;
    flex-direction: row;
}

.contact-content label {
    margin-right: 15px;
}

.contact-content input {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    width: 230px;
    height: 35px;
    padding-left: 10px;
}