* {
    margin: 0;
}

.navbar-logo {
    width: 130px;
    justify-self: flex-start;
    margin: 5px;
}

.index-nav {
    background: #033155;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 17px;
    padding-bottom: 17px;
}

.narbar-links {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
}

.navbar-link {
    margin: 15px;
    color: #EEE;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    font-size: 17px;
}

.navbar-link:hover {
    color: #CCC;
}

.navbar-button {
    padding: 10px 10px 10px 10px;
    border: solid 1px;
    border-radius: 30px;
}

.navbar-button:hover {
    background-color: rgba(255, 255, 255, .7);
    color: #000
}

@media only screen and (max-width: 1300px) {
    .navbar-link {
        font-size: 14px;
        margin: 5px;
    }
  }
