table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    margin: 5px;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

.tl-button {
  border: none;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 12px;
  cursor: pointer;
}

.tl-button-approve {
  border: 2px solid #4CAF50; /* Green */
}

.tl-button-approve:hover {
  border: 2px solid white;
  background-color: #4CAF50;
  color: white;
}

.tl-button-reject:hover {
  border: 2px solid white;
  background-color: #FF0000;
  color: white;
}

.tl-button-reject {
  border: 2px solid #FF0000;
}

.tl-button-neutral {
  border: 3px dotted #29d8d6;
}

.tl-button-neutral:hover{
  border: 2px dotted white;
  background-color: #29d8d6;
  color: white;
}

.created-bg {
  background-color: #CCC;
}

.withdrawn-bg {
  background-color: rgba(249, 206, 13, 0.3);
}

.approved-bg {
  background-color: rgba(144, 238, 144, 0.3);
}

.rejected-bg {
  background-color: rgba(240, 128, 128, 0.3);
}

.posted-bg {
  background-color: rgba(59, 119, 231, 0.3);
}

.trinder-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f4f4f9;
}

.list-card-widgets {
  font-family: sans-serif;
  display: flex;
  flex: 1;
  padding: 12px;
  background-color: #f4f4f9;
  flex-wrap: wrap;
  flex-direction: row;
  padding-bottom: 0px;
}

.list-card-widget {
  flex: 1;
  margin: 12px;
  background-color: #FFF;
  width: 220px;
  height: 70px;
  flex-wrap: wrap;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 12px;
  margin-bottom: 31px;
}

.list-card-widget .list-card-widget-title {
  padding-left: 12px;
  color: rgb(120, 120, 120)
}

.trinder-list h1 {
  font-family: sans-serif;
  padding-left: 24px;
  padding-top: 12px;
  padding-bottom: 0;
  background-color: #f4f4f9;
}

.list-card-widget p {
  padding-left: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 34px;
}



.color-key {
  width: 240px;
}

.list-card-controls {
  background-color: #f4f4f9;
  padding-top: 0;
  padding-bottom: 12px;
  padding-left: 12px;
}