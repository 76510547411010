.rules-content {
    padding-top: 3vh;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 45px;
}

.rules-contnet p {
    padding: 15px;
    text-align: left;
}

rules-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
}

h2 {
    padding-top: 20px;
    padding-bottom: 20px;
}

ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 45px;
}

li {
    padding: 3px;
}