.notice-banner {
    display: flex;
    background-color: #1B6FAB;
    align-items: center;
    justify-content: space-around;
    color: #EEE;
    font-family: 'Poppins', sans-serif;
    padding: 5px;
}

.notice-banner i {
    margin-left: 12px;
    color: salmon;
    cursor: pointer;
}

.notice-title {
    font-style: bold;
    font-weight: 800;
}