.write-content {
    padding-top: 3vh;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 290px;
    padding-right: 290px;
}

.write-content h1 {
    color: #044D87;
    font-size: 40px;
}

.write-content span {
    color: #666262;
}

.write-content-p {
    font-size: 17px;
    margin-top: 25px;
}

.write-content a {
    text-decoration: none;
    color: #044D87;
}

.write-form {
    margin-top: 20px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
}

.write-form textarea {
    width: 95%;
    border: solid 1px #CCCCCC; 
    border-radius: 15px;
    resize: none;
    font-size: 17px;
    padding: 20px;
    align-self: center;
}

.write-consent {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
}

.write-consent input[type=checkbox] {
    margin-left: 20px;
    transform: scale(1.2)
}

.write-consent label {  
    margin-left: 15px;
    font-size: 17px;
}

.write-form input[type=submit] {
    align-self: center;
    width: 120px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 17px;
    padding-right: 17px;
    border-style: none;
    border-radius: 7px;
    cursor: pointer;
    background-color: #044D87;
    color: #FFFF;
    font-weight: 600;
}

.write-form input[type=submit]:hover {
    background-color: #CCC;
    color: #666262;
}

.form-error {
    background-color: #9B111E;
    padding-top: 15px;
    padding-bottom: 15px;
    color: whitesmoke;
    border-radius: 7px;
    margin: 12px;
}

.form-success {
    background-color: #74B72E;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    color: whitesmoke;
    border-radius: 7px;
    margin: 12px;
}

.form-success p {
    margin-left: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #666262AA;
    border-radius: 10px;
    color: white;
    font-family: 'Roboto Mono', monospace;
}

@media only screen and (max-width: 1300px) {
    .write-content {
        padding-left: 20px;
        padding-right: 20px;
    }
  }
