.track-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 34px;
    font-family: 'Poppins', sans-serif;
    padding-bottom: 1vh;
}

.track-content span {
    color: #0670B5;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    text-align: center;
    align-self: center;
}

.track-content h1 {
    padding-top: 13px;
    color: #044D87;
    text-align: center;
    align-self: center;
}

.track-content h2 {
    padding-bottom: 2vh;
    text-align: center;
    align-self: center;
}

.track-content p {
    font-family: 'Inter', sans-serif;
    padding-bottom: 3px;
}

.tracker {
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
    align-items: center;
}

.track-form {
    display: flex;
    align-items: center;
    justify-content: center;
}

.track-form input[type=text] {
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 7px;
    padding-bottom: 7px;
    border: solid 1px #CCC;
    border-radius: 7px;
    font-size: 17px;

}

.track-form .submitBtn {
    background-color: #0670B5;
    color: #EEE;
    border: solid 1px #CCC;
    font-size: 17px;
    border-radius: 9px;
    padding: 6px;
    margin-left: 12px;
    cursor: pointer;
}

.tracking-widget {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.tracking-trinder {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: 3px solid #044D87;
    border-radius: 25px 25px 10px 10px;
}

.tracking-trinder span {
    font-family: 'Poppins', sans-serif;
    background-color: #044D87;
    color: #EEE;
    width: 100%;
    border-radius: 26px 26px 0 0;
    letter-spacing: normal;
    text-transform: none;
}

.tracking-trinder p {
    padding-top: 2vh;
    padding-bottom: 2vh;
}

.tracking-trinder input {
    margin-top: 1vh;
    margin-bottom: 1vh;
    background-color: #9B111E;
    border: none;
    border-radius: 10px;
    color: #EEE;
    font-size: 16px;
    width: 20%;

}

.tracking-trinder input:hover {
    background-color: #8b101c;
    cursor: pointer;
}

.tracking-details table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}
  
.tracking-details td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.tracker tr:nth-child(even) {
    background-color: #dddddd;
}

.track-error {
    background-color: #8b101c;
    color: white;
    border-radius: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 8px;
    width: 290px;
    text-align: center;
}

@media only screen and (min-height: 900px) and (min-width: 1300px) {
    .tracker {
        margin-bottom: 21vh;
    }
  }
